import { configureStore } from '@reduxjs/toolkit';
import { localApi } from '../services/localApi';
import positionsReducer from './positionsSlice';

export const store = configureStore({
  reducer: {
    [localApi.reducerPath]: localApi.reducer,
    positions: positionsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localApi.middleware),
});
