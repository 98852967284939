import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ContextProvider } from './app/ContextProvider';
import App from './App';
import { store } from './store/store';
import 'antd/dist/antd.css';

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="755162512781-qkivkt23s2i8dechdj6lv20mt7feuv5k.apps.googleusercontent.com">
      <Router>
        <Provider store={store}>
          <ContextProvider>
            <App />
          </ContextProvider>
        </Provider>
      </Router>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

