import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  BPositions: [],
  HPositions: [],
};

export const positionsSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    setBPositions: (state, action) => {
      state.BPositions = action.payload;
    },
    setHPositions: (state, action) => {
      state.HPositions = action.payload;
    },
    clearPositions: (state) => {
      state.BPositions = [];
      state.HPositions = [];
    }
  },
});

export const { setBPositions, setHPositions, clearPositions } = positionsSlice.actions;
export default positionsSlice.reducer;
