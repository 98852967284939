import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const createRequest = (url, method = 'GET', userData, body = null) => {
  const requestConfig = {
    url,
    // headers: userData ? { Authorization: `Bearer ${userData}` } : { 'Content-Type': 'application/json' },
    headers: {
      'Content-Type': 'application/json', // Only necessary header remains
    },
  };
  if (method !== 'GET') {
    requestConfig.method = method;
    requestConfig.body = JSON.stringify(body);
  }
  return requestConfig;
};

export const localApi = createApi({
  reducerPath: 'localApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://zero-delta.io' : 'http://localhost:5555',
    credentials: 'include', // Important to include cookies in the request
  }),
  endpoints: (builder) => ({
  
    // getBPositions: builder.query({
    //   query: () => createRequest('/api/get-B-positions'),
    // }),

    // getHPositions: builder.query({
    //   query: () => createRequest('/api/get-H-positions'),
    // }),

    getPositions: builder.query({
      query: (body) => createRequest('/api/get-positions', 'GET', body),
    }),

    getAllAssets: builder.query({
      query: (body) => createRequest('/api/get-all-assets', 'GET', body),
    }),

    getIsolatedWallets: builder.query({
      query: (body) => createRequest('/api/get-isolated-wallets', 'GET', body),
    }),
    
    getCrossWallet: builder.query({
      query: (body) => createRequest('/api/get-cross-wallet', 'GET', body),
    }),
    
    disableIsolatedWallets: builder.mutation({
      query: (body) => createRequest('/api/disable-isolated-wallets', 'POST', null, body),
    }),

    getAssetBalances: builder.mutation({
      query: (body) => createRequest('/api/get-asset-balances', 'POST', null, body),
    }),

    getSymbolSpreadAllPairs: builder.mutation({
      query: (body) => createRequest('/api/get-symbol-spread-all-pairs', 'POST', null, body),
    }),
    
    getSymbolSpread: builder.mutation({
      query: (body) => createRequest('/api/get-symbol-spread', 'POST', null, body),
    }),

    getFrHistory: builder.mutation({
      query: (body) => createRequest('/api/get-fr-history', 'POST', null, body),
    }),


    getSpreads: builder.query({
      query: (body) => createRequest('/api/get-spreads', 'GET', body),
    }),

    // getRealtimeFrs: builder.query({
    //   query: (body) => createRequest('/api/get-realtime-frs', 'GET', body),
    // }),
    
    getUsdtInterest: builder.query({
      query: (body) => createRequest('/api/get-usdt-interest', 'GET', body),
    }),

    // // Add refresh token endpoint
    // refreshToken: builder.mutation({
    //   query: (body) => createRequest('/api/refresh-token', 'POST', null, body),
    // }),

    // refreshCookie: builder.mutation({
    //   query: (body) => createRequest('/api/refresh-cookie', 'POST', null, body),
    // }),

    refreshCookie: builder.query({
      query: (body) => createRequest('/api/refresh-cookie', 'GET', body),
    }),

    postTrade: builder.mutation({
      query: (body) => createRequest('/api/post-trade', 'POST', null, body),
    }),

    listTrade: builder.mutation({
      query: (body) => createRequest('/api/list-trade', 'POST', null, body),
    }),

    resetTrade: builder.mutation({
      query: (body) => createRequest('/api/reset-trade', 'POST', null, body),
    }),

    stopTrade: builder.mutation({
      query: (body) => createRequest('/api/stop-trade', 'POST', null, body),
    }),

    getHistoricalSpread: builder.mutation({
      query: (body) => createRequest('/api/get-historical-spread', 'POST', null, body),
    }),

    getEarnLoanAPY: builder.mutation({
      query: (body) => createRequest('/api/get-earn-loan-apy', 'POST', null, body),
    }),

    postAssetAction: builder.mutation({
      query: (body) => createRequest('/api/post-asset-action', 'POST', null, body),
    }),

    restartServer: builder.mutation({
      query: (body) => createRequest('/api/restart-data-server', 'POST', null, body),
    }),

    // getSymbol: builder.mutation({
    //   query: (data) => {
    //     const { body, userData } = data;
    //     return createRequest('/api/get-symbol', 'POST', userData, body)
    //   },
    // }),

    getDummyData: builder.query({
      query: () => createRequest('/api/my-endpoint'),
    }),

    getOrders: builder.query({
      query: (body) => createRequest('/api/get-orders', 'GET', body),
    }),

    cancelOrder: builder.mutation({
      query: (body) => createRequest('/api/cancel-order', 'POST', null, body),
    }),
    
    buyLimit: builder.mutation({
      query: (body) => createRequest('/api/buy-limit', 'POST', null, body),
    }),

    buyMarket: builder.mutation({
      query: (body) => createRequest('/api/buy-market', 'POST', null, body),
    }),

    sellLimit: builder.mutation({
      query: (body) => createRequest('/api/sell-limit', 'POST', null, body),
    }),

    sellMarket: builder.mutation({
      query: (body) => createRequest('/api/sell-market', 'POST', null, body),
    }),

    getReceivedFr: builder.mutation({
      query: (body) => createRequest('/api/get-received-fr', 'POST', null, body),
    }),

  }),
});

export const {
  useGetPositionsQuery, useGetAllAssetsQuery, useGetIsolatedWalletsQuery, useGetCrossWalletQuery,
  useGetAssetBalancesMutation,useGetSymbolSpreadAllPairsMutation, useGetSymbolSpreadMutation, useGetFrHistoryMutation,
  useGetDummyDataQuery, useGetSpreadsQuery, useGetUsdtInterestQuery, useRefreshCookieQuery, 
  usePostTradeMutation, useListTradeMutation, useResetTradeMutation,
  useStopTradeMutation, useGetHistoricalSpreadMutation, useGetEarnLoanAPYMutation, usePostAssetActionMutation,
  useRestartServerMutation, useDisableIsolatedWalletsMutation, useGetOrdersQuery, useCancelOrderMutation, 
  useBuyLimitMutation, useBuyMarketMutation, useSellLimitMutation, useSellMarketMutation, useGetReceivedFrMutation } = localApi;
