import React, { useEffect, useState } from 'react';
import { Button, Spin,Table, notification} from 'antd';
import { RedoOutlined } from '@ant-design/icons';
// import { GoogleLogin } from 'react-google-login';
import {  useGetPositionsQuery,useGetFrHistoryMutation } from '../services/localApi';
import './common.css';
import useTokenRefresh from '../services/useTokenRefresh';
import { showFrPopup } from './common';
import CheckAssetBalancesComponent from './CheckAssetBalancesComponent';
import CheckSymbolSpreadComponent from './CheckSymbolSpreadComponent';
import CheckFundingRateHistoryComponent from './CheckFundingRateHistoryComponent';
import PostTradeComponent from './PostTradeComponent';
import TradeInforComponent from './TradeInforComponent';
import AssetControlComponent from './AssetControlComponent';
import { useDispatch } from 'react-redux';
import { setBPositions, setHPositions } from '../store/positionsSlice';

const Positions = () => {
  useTokenRefresh();
  const dispatch = useDispatch();
  const [getFrHistory] = useGetFrHistoryMutation();
  const [initialLoad, setInitialLoad] = useState(true);
  const { data, isFetching, refetch, error } = useGetPositionsQuery();
  const [lastUpated, setLastUpdated] = useState('');
  const [localBPositions, setLocalBPositions] = useState([]); // renamed from BPositions
  const [localHPositions, setLocalHPositions] = useState([]); // renamed from HPositions
  
  const bColumns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        render: (text, record, index) => (
          <span 
              style={{ display: 'block', cursor: 'pointer', fontWeight: 'bold' }} 
              onClick={(e) => handleCellClick(e, record.symbol, 'B')} >
              {text}
          </span>
      ),
    },
    {
        title: 'B_size',
        dataIndex: 'B_size',
        className: 'binance-background',
        sorter: (a, b) => a.B_size - b.B_size,
        align: 'right',
    },
    {
        title: 'B_USD',
        dataIndex: 'B_USD',
        className: 'binance-background',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.B_USD - b.B_USD,
        align: 'right',
    },
    {
        title: 'B_PnL',
        dataIndex: 'B_PnL',
        className: 'binance-background',
        align: 'right',
        sorter: (a, b) => a.B_PnL - b.B_PnL,
    },
    {
        title: 'B_fr',
        dataIndex: 'B_fr',
        className: 'binance-background',
        sorter: (a, b) => a.B_fr - b.B_fr,
        render: (value) => {
          if (typeof value === 'number') {
              return (
                  <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
                      {value.toFixed(3)}
                  </span>
              );
          } else {
              return (
                  <span>
                      {value}
                  </span>
              );
          }
        },
        align: 'right',
    },
    {
        title: 'B_liq',
        dataIndex: 'B_liq',
        className: 'binance-background',
        align: 'right',
    },
    {
        title: 'out',
        dataIndex: 'out',
        className: 'binance-background',
        sorter: (a, b) => a.out - b.out,
        render: (value) => {
            if (typeof value === 'number') {
                return (
                    <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
                        {value.toFixed(3)}
                    </span>
                );
            } else {
                return (
                    <span>
                        {value}
                    </span>
                );
            }
        },
        align: 'right',
    },
  ];
  const hColumns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        render: (text, record, index) => (
          <span 
              style={{ cursor: 'pointer', fontWeight: 'bold'  }} 
              onClick={(e) => handleCellClick(e, record.symbol, 'H')}
          >
              {text}
          </span>
      ),
    },
    {
        title: 'H_size',
        dataIndex: 'H_size',
        className: 'bybit-background',
        sorter: (a, b) => a.H_size - b.H_size,
        align: 'right',
    },
    {
        title: 'H_USD',
        dataIndex: 'H_USD',
        className: 'bybit-background',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.H_USD - b.H_USD,
        align: 'right',
    },
    {
        title: 'H_PnL',
        dataIndex: 'H_PnL',
        className: 'bybit-background',
        align: 'right',
        sorter: (a, b) => a.H_PnL - b.H_PnL,
    },
    {
        title: 'H_fr',
        dataIndex: 'H_fr',
        className: 'bybit-background',
        sorter: (a, b) => a.H_fr - b.H_fr,
        // render: (value) => (
        //   <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
        //     {value.toFixed(3)}
        //   </span>
        // ),
        render: (value) => {
          if (typeof value === 'number') {
              return (
                  <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
                      {value.toFixed(3)}
                  </span>
              );
          } else {
              return (
                  <span>
                      {value}
                  </span>
              );
          }
        },
        align: 'right',
    },
    {
        title: 'H_liq',
        dataIndex: 'H_liq',
        className: 'bybit-background',
        align: 'right',
    },
    {
        title: 'out',
        dataIndex: 'out',
        className: 'bybit-background',
        sorter: (a, b) => a.out - b.out,
        render: (value) => {
            if (typeof value === 'number') {
                return (
                    <span style={{ color: value < 0 ? 'red' : 'black', fontWeight: value < 0 ? 'bold' : 'normal' }}>
                        {value.toFixed(3)}
                    </span>
                );
            } else {
                return (
                    <span>
                        {value}
                    </span>
                );
            }
        },
        align: 'right',
    },
  ];

  const handleCellClick = async (e, symbol, exchange) => {
      // Prevent default behavior
      e.preventDefault();
      const res = await getFrHistory({ symbol: symbol, exchange: exchange }).unwrap();
      showFrPopup(e, symbol, exchange, res.response, res.summary); // Show result at popup
  };
  
  const handleRefreshPositionsButton = async () => {
    await refetch();
  };

  const handleTopEarnListUpdate = (assets) => {
    localStorage.setItem('topEarnList', assets);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60000); // 60 seconds

    // Update states whenever data changes
    if (data) {
      // Update local state
      setLocalBPositions(data.B || []);
      setLocalHPositions(data.H || []);
      
      // Update Redux state and localStorage with positions arrays
      if (data.B?.positions) {
        dispatch(setBPositions(data.B.positions));
        localStorage.setItem('BPositions', JSON.stringify(data.B.positions));
      }
      if (data.H?.positions) {
        dispatch(setHPositions(data.H.positions));
        localStorage.setItem('HPositions', JSON.stringify(data.H.positions));
      }
      
      setLastUpdated(new Date().toLocaleString('en-GB'));
    }

    // Clear states on error
    if (error && !isFetching) {
      notification.error({
        message: 'Cannot load positions',
        description: 'Login again !!',
      });
      setLocalBPositions([]);
      setLocalHPositions([]);
      dispatch(setBPositions([]));
      dispatch(setHPositions([]));
      localStorage.removeItem('BPositions');
      localStorage.removeItem('HPositions');
    }

    return () => clearInterval(intervalId);
  }, [data, error, isFetching, dispatch]);

  // Add immediate refetch on mount
  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="tables-container">
      <div style={{ marginRight: '20px', flex: '0.75', minWidth: '300px' }}>
        <PostTradeComponent />
        <AssetControlComponent />
        <CheckAssetBalancesComponent />
        <CheckSymbolSpreadComponent />
        <CheckFundingRateHistoryComponent onTopEarnListUpdate={handleTopEarnListUpdate} />
        <TradeInforComponent />
      </div>

      {/* First table */}
      {/* uncomment below 3 lines to merge 2 positions table in 1 div */}
      {/* <div className="table-wrapper" style={{ flex: '2' }}> */}
        {/* <HistoricalSpreadComponent style={{ width: '100%' }} /> */}
        {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}> */}
          <div style={{ flex: '1' }}>
            <div className="status-grid">
              <div className="status-div">
                <div>B positions</div>
                <div style={{ paddingRight: '150px' }}>
                {(isFetching) && (
                    <Spin style={{ marginLeft: '10px' }} />
                  )}
                  <Button type="link" ghost shape="round"  style={{marginLeft: '25px'}} onClick={() => handleRefreshPositionsButton()} icon={<RedoOutlined />} > Refresh </Button> 
                </div>
              </div>
              <div className="status-div">
                <div>Ratio :</div>
                <div style={{ paddingRight: '150px' }}>
                  {localBPositions.ratio}
                  {' '}
                  %
                </div>
              </div>
              <div className="status-div">
                <div>Margin balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {localBPositions.margin_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>Wallet balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {localBPositions.wallet_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>LONG :</div>
                <div style={{ paddingRight: '150px', color: 'green' }}>
                  {localBPositions.long}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>SHORT :</div>
                <div style={{ paddingRight: '150px', color: 'red' }}>
                  {localBPositions.short}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>Cross margin :</div>
                <div style={{ paddingRight: '150px' }}>
                  {localBPositions.cross_margin}
                </div>
              </div>
            </div>
            <Table
              columns={bColumns}
              dataSource={localBPositions.positions}
              pagination={false}
              size="small"
                // onChange={onChange}
              style={{ marginRight: '20px' }}
            />
          </div>
          <div style={{ flex: '1' }}>
            <div className="status-grid">
              <div className="status-div">
                H positions
              </div>
              <div className="status-div">
                <div>Ratio :</div>
                <div style={{ paddingRight: '150px' }}>
                  {localHPositions.ratio}
                  {' '}
                  %
                </div>
              </div>
              <div className="status-div">
                <div>Margin balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {localHPositions.margin_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>Wallet balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {localHPositions.wallet_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>LONG :</div>
                <div style={{ paddingRight: '150px', color: 'green' }}>
                  {localHPositions.long}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>SHORT :</div>
                <div style={{ paddingRight: '150px', color: 'red' }}>
                  {localHPositions.short}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>updated :</div>
                <div style={{ paddingRight: '150px', color: 'blue' }}>
                  {lastUpated}
                  {' '}
                  $
                </div>
              </div>
            </div>
            <Table
              columns={hColumns}
              dataSource={localHPositions.positions}
              pagination={false}
              size="small"
            />
          </div>
        </div>
      // </div>

    // </div>

  );
};

export default Positions;

