// CheckAssetBalancesComponent.js
import React, { useState } from 'react';
import { Table, Button, Dropdown, Menu, notification, Modal } from 'antd';
import { useGetFrHistoryMutation, useGetEarnLoanAPYMutation, useGetReceivedFrMutation } from '../services/localApi';
import './common.css';
import { showFrPopup } from './common';

const CheckFundingRateHistoryComponent = ({ onTopEarnListUpdate }) => {
  const [textBoxCheckFundingRate, setTextBoxCheckFundingRate] = useState('');
  const [getFrHistory] = useGetFrHistoryMutation();
  const [getEarnLoan] = useGetEarnLoanAPYMutation();
  const [getReceivedFr] = useGetReceivedFrMutation();
  const [frData, setFrData] = useState(null);
  const [earnLoanData, setEarnLoanData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [receivedFrData, setReceivedFrData] = useState(null);

  const [earnLength, setEarnLength] = useState(() => {
    // Get saved value from localStorage or default to 20
    const savedValue = localStorage.getItem('earnLength');
    return savedValue !== null ? parseInt(savedValue) : 20;
  });
  
const fundingRateColumns = [
  {
    title: 'Time',
    dataIndex: 'time',
  },
  {
    title: 'B',
    dataIndex: 'B',
  },
  {
    title: 'H',
    dataIndex: 'H',
  },
];

const earnLoanColumns = [
  {
    title: 'Coin',
    dataIndex: 'asset',
    render: (text, record, index) => (
      <span 
        style={{ display: 'block', cursor: 'pointer'}} 
        onClick={(e) => handleCellClick(e, text)} >
        {text}
      </span>
    ),
  },
  {
    title: 'APY',
    dataIndex: 'latestAPR',
  },
];
const receivedFrColumns = [
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: 'Symbol', 
    dataIndex: 'symbol',
    key: 'symbol',
    sorter: (a, b) => a.symbol.localeCompare(b.symbol)
  },
  {
    title: 'B',
    dataIndex: 'B',
    key: 'B', 
    sorter: (a, b) => a.B - b.B,
    render: (value) => (
      <span style={{ 
        color: value < 0 ? 'red' : 'inherit',
        fontWeight: value < 0 ? 'bold' : 'normal'
      }}>
        {Number(value).toFixed(2)}
      </span>
    )
  },
  {
    title: 'H',
    dataIndex: 'H',
    key: 'H',
    sorter: (a, b) => a.H - b.H, 
    render: (value) => (
      <span style={{
        color: value < 0 ? 'red' : 'inherit',
        fontWeight: value < 0 ? 'bold' : 'normal'
      }}>
        {Number(value).toFixed(2)}
      </span>
    )
  }
];

  const handleCellClick = async (e, asset) => {
    if (asset==='earn' || asset==='borrow' || asset==='flex loan') {
      return;
    }
    // Prevent default behavior
    e.preventDefault();
    const res = await getFrHistory({ symbol: asset + "USDT", exchange: "H" }).unwrap();
    showFrPopup(e, asset + "USDT", "H", res.response, res.summary); // Show result at popup
  };

  const handleEarnLengthSelect = (e) => {
    const newValue = parseInt(e.key);
    localStorage.setItem('earnLength', newValue);
    setEarnLength(newValue);
  }

  const handleEarnLengthChange = (e) => {
    const newValue = parseInt(e.target.value);
    localStorage.setItem('earnLength', newValue);
    setEarnLength(newValue);
  };

  const handleButtonCheckFundingRate = async () => {
    setEarnLoanData(null);
    setFrData(null);
    // eslint-disable-next-line
    try {
      const res = await getFrHistory({ symbol: textBoxCheckFundingRate }).unwrap();
      setFrData(res);
    } catch (err) {
      console.error('Check FR error details:', err);
      notification.error({
        message: 'Check FR',
        description: `Error : ${JSON.stringify(err)}`,
      });
    }
  };

  const handleButtonGetEarnLoan = async () => {
    setEarnLoanData(null);
    setFrData(null);
    // eslint-disable-next-line
    try {
      const res = await getEarnLoan({ symbol: textBoxCheckFundingRate, length: earnLength }).unwrap();
      setEarnLoanData(res);
      if (textBoxCheckFundingRate === '') {
        // only update top earn list when get all assets
        const topEarnList = res.response.map(item => item.asset + "USDT");
        onTopEarnListUpdate(topEarnList); // Pass the assets list to the parent component
      }
      
    } catch (err) {
      console.error('Get APY error details:', err);
      notification.error({
        message: 'Get APY',
        description: `Error : ${JSON.stringify(err)}`,
      });
    }
  };

  const handleButtonReceivedFr = async () => {
    try {
      const res = await getReceivedFr({ symbol: textBoxCheckFundingRate }).unwrap();
      setReceivedFrData(res);
      setIsModalVisible(true);
    } catch (err) {
      console.error('Received FR error details:', err);
      notification.error({
        message: 'Received FR',
        description: `Error : ${JSON.stringify(err)}`,
      });
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const earnLengthsMenu = (
    <Menu onClick={handleEarnLengthSelect}>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="40">40</Menu.Item>
      <Menu.Item key="60">60</Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '10px', position: 'relative' }}>
          <div style={{ width: '100px' }}>FR / Earn  : </div>
          <input style={{ marginLeft: '5px', width: '150px'}} type="text" value={textBoxCheckFundingRate} onChange={(e) => { setTextBoxCheckFundingRate(e.target.value); }} />
          <div style={{ position: 'absolute', right: '0' }}>
            n 
            <Dropdown overlay={earnLengthsMenu}>
              <Button style={{ width:'40px', marginLeft: '2px', marginRight: '2px' }} >
                {earnLength}
              </Button>
            </Dropdown>
            <Button className="normal-button" style={{width:'55px'}} onClick={handleButtonGetEarnLoan}>APY</Button>
            <Button className="normal-button" style={{ marginLeft: '5px' }} type="primary" onClick={handleButtonCheckFundingRate}> Get fr </Button>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
          <Button className="normal-button" onClick={handleButtonReceivedFr}> FR detail </Button>
        </div>
      </div>
      {frData
              && (
              <div>
                <div style={{ fontSize: '15px' }}>{ frData.description } </div>
                <Table
                  columns={fundingRateColumns}
                  dataSource={frData.response}
                  size="small"
                  style={{ marginTop: '20px' }}
                  pagination={false}
                />
                <div style={{ fontSize: '15px' }}>{ frData.summary } </div>
              </div>
)}

      {earnLoanData
              && (
              <div>
                <div style={{ fontSize: '15px' }}>
                  {earnLoanData.description}
                </div>
                <Table
                  columns={earnLoanColumns}
                  dataSource={earnLoanData.response}
                  size="small"
                  style={{ marginTop: '20px' }}
                  pagination={false}
                />
              </div>
)}

      <Modal
        title="Received Funding Rate"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {receivedFrData && (
          <Table
            columns={receivedFrColumns}
            dataSource={receivedFrData.response}
            size="small"
            pagination={false}
          />
        )}
      </Modal>

    </div>
  );
};

export default CheckFundingRateHistoryComponent;
